body {
  margin: 0 !important;
}
.groupmemberlist {
  height: 100%; /* Full height of the container */
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 40px; /* Add padding to the bottom */
  width: 226px;
}

.groupmemberlist::-webkit-scrollbar {
  width: 3px !important;
}

.groupmemberlist::-webkit-scrollbar-thumb {
  background-color: #494F73 !important;
  border-radius: 20px;
}

// .memtxtimg {
//   display: flex;
//   align-items: center;
//   margin-bottom: 10px;
// }

.memavatar {
  display: flex;
  align-items: center;
  // border: 1.5px solid rgb(58, 163, 82);
}

.memtxtimg img {
  height: 34px;
  width: 34px;
  border-width: 0px !important;
}

.memtxtimg h3 {
  font-size: 14px;
  font-weight: 500;
  margin: 0px 0px 0px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 109px;
}

.memtxtimg h6 {
  font-size: 12px;
  font-weight: 300;
  color: #8F98A3;
  line-height: 18px;
  margin: 0px 0px 0px 10px;
}

.memberBox {
  height: 100%;
  border-left: 1px solid #CBD6E2;
}

.memberDiv {
  width: 100%;
  height: 34px;
  padding: 5px 20px;
  gap: 10px;
  background-color: #F5F8FA;
}

.memberDiv h4 {
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
}

.onlineCount {
  color: #3AA352;
}


.you {
  position: absolute;
  // right: 36px; 
  top: 11px;
  width: 6px;
}
.memtxtimg {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
